export function numberWithCommas(x: any, decimals: number = 8): string {
    if (!x) {
        return "0";
    }
    const str = getRegularNumber(x);
    const parts = str.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(parts.length > 1)
        parts[1] = parts[1].slice(0, decimals)
    return decimals === 0 ? parts[0] : parts.join(".");
}


export function getRegularNumber(n: number): string {
    let sign = n < 0 ? "-" : "";
    let toStr = n.toString();

    if (!/e/i.test(toStr)) {
        return n.toString();
    }

    let [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);

    const exponent = parseInt(pow, 10);

    return exponent < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(exponent) - 1, 0)) + lead + decimal
        : sign + lead + (exponent >= decimal.length
            ? decimal + "0".repeat(Math.max(exponent - decimal.length, 0))
            : decimal.slice(0, exponent) + "." + decimal.slice(exponent));
}

export function truncateToDecimals(n: number, decimals: number) {
    return Math.trunc(n * 10**decimals) / 10**decimals
}

export function calculateTotalBalance(coinsData: any, balanceData: any) {
    let total_balance_usdt = 0 
    if(coinsData && balanceData) {
        Object.keys(coinsData.coins).forEach((coin) => {
            const c = coinsData.coins[coin]
            total_balance_usdt += (balanceData.balance[coin].actual + balanceData.balance[coin].orders) * c.price
        })
    }
    return total_balance_usdt
}