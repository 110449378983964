import { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBalance } from "../../hooks/useBalance"
import { useCoins } from "../../hooks/useCoins"
import { numberWithCommas, calculateTotalBalance } from "../../utils/functions"
import { FaChevronRight, FaChevronDown, FaSortUp, FaSortDown } from "react-icons/fa6";
import { IoMdSwap } from "react-icons/io";
import { FiArrowUpLeft, FiArrowDownRight } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { PieChart } from "react-minimal-pie-chart"
import useModalStore from "../../stores/ModalStore"
import AssetModal from "../../modals/AssetModal/AssetModal"
import coinlogos from "../../utils/coinlogos"
import coincolors from "../../utils/coincolors"
import "./Wallet.css"

const Wallet = () => {

    const { t } = useTranslation('common')
    
    const { data: balanceData } = useBalance()
    const { data: coinsData } = useCoins()

    const [showSmallBalances, setShowSmallBalances] = useState(false)
    const [sortingParam, setSortingParam] = useState("")
    const [filterInput/* , setFilterInput */] = useState("")
    const [selectedCoin, setSelectedCoin] = useState("")

    const toggleSmallBalances = () => {
        setShowSmallBalances((prev) => {
            return !prev
        })
    }

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent
        }
    })

    let total_balance_usdt = calculateTotalBalance(coinsData, balanceData)

    let bigPercentageElems: JSX.Element[] = []
    let smallPercentageElems: JSX.Element[] = []
    let allPercentageElems: JSX.Element[] = []
    let chartData: any[] = []


    const sortListByIndex = (list: any[]) => {
        return list.sort(
            (a, b) => {
                return coinsData.coins[a].index - coinsData.coins[b].index
            }
        )
    }
        
    if(coinsData && balanceData) {
        let first = true
        sortListByIndex(Object.keys(coinsData.coins)).forEach((coin) => {
            const percentage = ((balanceData.balance[coin].actual + balanceData.balance[coin].orders) * coinsData.coins[coin].price * 100 / total_balance_usdt) || 0
            if(percentage > 0.1) {
                bigPercentageElems.push(<div onClick={() => {setSelectedCoin(coin)}} key={coin} style={{border: selectedCoin === coin ?  "1px solid #8ffdfc" :  "1px solid #273141"}} className="coin-percentage-cont"><img className="percentage-logo" src={coinlogos[coin as keyof typeof coinlogos]} /><span className="percentage-coin-name">{coin}</span><span className="coin-percentage">{percentage.toFixed(2)}%</span></div>)
                chartData.push({key: coin, title: coin, value: percentage, color: coincolors[coin as keyof typeof coincolors]})
                if(!selectedCoin && first) {
                    setSelectedCoin(coin)
                    first = false
                }
            }
            else if(percentage > 0 && percentage < 0.1) {
                const elem = (
                    <div key={coin} className="small-balance-item-cont">
                        <img className="percentage-logo" src={coinlogos[coin as keyof typeof coinlogos]} />
                        <div className="amount-price-cont">
                            <div className="amount-cont">
                                <span className="currency-name">{coin}</span>
                                <span className="amount-value">{numberWithCommas(balanceData.balance[coin].actual + balanceData.balance[coin].orders, coinsData?.coins[coin]?.decimals)}</span>
                            </div>
                            <div className="price-cont">
                                <span className="currency-long-name">{t(`long_coin_names.${coin}`)}</span>
                                <span className="price-value">{numberWithCommas(coinsData.coins[coin].price * (balanceData.balance[coin].actual + balanceData.balance[coin].orders), 2)} USDT</span>
                            </div>
                        </div>
                    </div>
                )
                smallPercentageElems.push(elem)
            }
            else {
                allPercentageElems.push(<div key={coin} style={{border: "2px solid #273141"}} className="coin-percentage-cont"><img className="percentage-logo" src={coinlogos[coin as keyof typeof coinlogos]} /><span className="percentage-coin-name">{coin}</span><span className="coin-percentage">{percentage.toFixed(2)}%</span></div>)
            }
        })
    }

    const handleSetSortingParam = (sort: string) => {
        setSortingParam((prev) => {
            if (prev === sort) {
                return `${sort}-desc`
            }
            return sort
        })
    }

    const sortCoins = (coins: string[]) => {
        switch (sortingParam) {
            case 'amount':
                return [...coins].sort((a, b) => (balanceData.balance[b].actual + balanceData.balance[b].orders) - (balanceData.balance[a].actual + balanceData.balance[a].orders))
            case 'price':
                return [...coins].sort((a, b) => coinsData.coins[b].price - coinsData.coins[a].price)
            case 'change':
                return [...coins].sort((a, b) => coinsData.coins[b].price_24h - coinsData.coins[a].price_24h)
            case 'amount-desc':
                return [...coins].sort((a, b) => (balanceData.balance[a].actual + balanceData.balance[a].orders) - (balanceData.balance[b].actual + balanceData.balance[b].orders))
            case 'price-desc':
                return [...coins].sort((a, b) => coinsData.coins[a].price - coinsData.coins[b].price)
            case 'change-desc':
                return [...coins].sort((a, b) => coinsData.coins[a].price_24h - coinsData.coins[b].price_24h)
            default:
                return [...coins].sort((a, b) => coinsData.coins[a].index - coinsData.coins[b].index)
        }
    }

    const filteredCoins = () => {
        const coins = Object.keys(coinsData.coins)
        const capi = filterInput.toUpperCase()
        const filtered = coins.filter(function (str) { return str.includes(capi) })

        return sortCoins(filtered)
    }

    const assetElems = (coinsData && balanceData) ? filteredCoins().map((coin) => {
        return (
            <div key={coin} className="asset-item-cont">
                <div className="asset-logo-name-cont">
                    <img className="asset-logo" src={coinlogos[coin as keyof typeof coinlogos]}/>
                    <div className="asset-name-longname">
                        <p>{coin}</p>
                        <p className="asset-long-name">{t(`long_coin_names.${coin}`)}</p>
                    </div>
                </div>
                <div className="asset-item-infocont">
                    <div className="asset-item-totals-cont">
                        <span>{numberWithCommas(`${balanceData.balance[coin].actual + balanceData.balance[coin].orders}`,coinsData?.coins[coin]?.decimals)} {coin}</span>
                        {coin !== "USDT" && <span className="asset-item-usdt-total">{numberWithCommas(`${coinsData.coins[coin].price * (balanceData.balance[coin].actual + balanceData.balance[coin].orders)}`, 2)} USDT</span>}
                    </div>
                </div>
                <div className="asset-item-infocont">
                    <span>{numberWithCommas(`${coinsData.coins[coin].price}`)} USDT</span>
                </div>
                <div className="asset-item-infocont">
                    <span style={{fontWeight: "600", color: coinsData.coins[coin].price_24h === 0 ? "white" : coinsData.coins[coin].price_24h > 0 ? "#46CE6C" : "#EE4747"}}>{coinsData.coins[coin].price_24h.toFixed(2)}%</span>
                </div>
                <div className="asset-item-infocont">
                    <Link className="asset-item-action-link" to={`/converter/${coin}/${coin === "USDT" ? "COP" : "USDT"}`}><IoMdSwap /></Link>
                    <Link className="asset-item-action-link" to={`/deposit/${coin}`}><FiArrowDownRight /></Link>
                    <Link className="asset-item-action-link" to={`/withdraw/${coin}`}><FiArrowUpLeft /></Link>
                </div>
                <div className="asset-small-window-infocont">
                    <div className="asset-item-totals-small-cont">
                        <span>{numberWithCommas(`${balanceData.balance[coin].actual + balanceData.balance[coin].orders}`,coinsData?.coins[coin]?.decimals)} {coin}</span>
                        {coin !== "USDT" && <span className="asset-item-usdt-total">{numberWithCommas(`${coinsData.coins[coin].price * (balanceData.balance[coin].actual + balanceData.balance[coin].orders)}`, 2)} USDT</span>}
                    </div>
                    <div onClick={() => {setModalContent(<AssetModal coin={coin} />)}} className="asset-item-dropdown-button" >
                        <RxDotsVertical style={{fontSize: "1.3rem"}} />
                    </div>
                </div>
            </div>
        )
    }) : []

    return (
        <div className="outlet-page-main-cont">
            <div className="wallet-page-main-cont">
                <div className="wallet-page-balance-cont">
                    <h1 className="wallet-title">{t('balance')}</h1>
                    <div className="wallet-balance-body">
                        <div className="wallet-balance-top-balance">
                            <p className="wallet-balance-total-balance-text">{t('total_balance')}:</p>
                            <span className="wallet-balance-total-balance-number">{total_balance_usdt ? `${numberWithCommas(total_balance_usdt, 2)} USDT` : "0 USDT"}</span>
                        </div>
                        <div className="wallet-balance-bottom-data">
                            <div className="wallet-balance-chart">
                               <PieChart data={chartData.length > 0 ? chartData : [{value: 100, color: "#8E9EB5"}]} animate rounded animationDuration={500} lineWidth={15} segmentsStyle={{ cursor: 'pointer' }}/>
                               {selectedCoin && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            display: "flex",
                                            flexDirection: "column",
                                            top: "50%",
                                            left: "50%",
                                            alignItems: "center",
                                            color: "white",
                                            padding: '8px',
                                            borderRadius: '8px',
                                            pointerEvents: 'none',
                                            gap: "5px",
                                            fontSize: "1rem",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            transform: "translate(-50%, -50%)"
                                        }}
                                    >
                                        <img className="percentage-logo" src={coinlogos[selectedCoin as keyof typeof coinlogos]} />
                                        <span className="tooltip-title">{selectedCoin}</span>
                                        <span>{numberWithCommas(balanceData.balance[selectedCoin].actual + balanceData.balance[selectedCoin].orders, coinsData?.coins[selectedCoin]?.decimals)}</span>
                                        {selectedCoin !== "USDT" && <span className="tooltip-equivalent-value">≈ {numberWithCommas(coinsData.coins[selectedCoin].price * (balanceData.balance[selectedCoin].actual + balanceData.balance[selectedCoin].orders), 2)} USDT</span>}
                                    </div>
                                )}
                            </div>
                            <div className="wallet-balance-data-splitter">
                                <div className="wallet-balance-coin-percentages-cont">
                                    {bigPercentageElems.length > 0 ? bigPercentageElems : allPercentageElems}
                                </div>
                                <div className="separator"></div>
                                {
                                    smallPercentageElems.length > 0 && <span onClick={toggleSmallBalances} className="small-balances-text" >{t('small_balances')} {showSmallBalances ? <FaChevronDown className="small-balances-icon"/> : <FaChevronRight className="small-balances-icon" />}</span>
                                }
                                <div className="wallet-balance-small-balances-cont">
                                    {showSmallBalances && <div className="small-balances-cont">
                                        {smallPercentageElems}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wallet-page-assets-cont">
                    <h1 className="wallet-title">{t('assets')}</h1>
                    <div className="wallet-assets-body">
                        <div className="wallet-assets-filterbar">
                            <div className="filterbar-coin-label-input-cont">
                                <div onClick={() => {handleSetSortingParam("")}}>
                                    <label className="filterbar-label">{t('coin')}</label>
                                </div>
                                {/* <input value={filterInput} onChange={(e) => {setFilterInput(e.target.value)}} className="filterbar-input" placeholder={t('search_coin')} /> */}
                            </div>
                            <div className="assets-filter">
                                <label className="assets-filter-label">{t('amount')}</label>
                                <div onClick={() => {handleSetSortingParam("amount")}} className="up-down-filter-icons-cont">
                                    <FaSortUp className={`sort-icon sort-topicon ${sortingParam === 'amount' ? 'sort-icon-active' : ''}`}/>
                                    <FaSortDown className={`sort-icon ${sortingParam === 'amount-desc' ? 'sort-icon-active' : ''}`}/>
                                </div>
                            </div>
                            <div style={{flex: "1"}} className="assets-filter">
                                <label className="assets-filter-label">{t('coin_price')}</label>
                                <div onClick={() => {handleSetSortingParam("price")}} className="up-down-filter-icons-cont">
                                    <FaSortUp className={`sort-icon sort-topicon ${sortingParam === 'price' ? 'sort-icon-active' : ''}`}/>
                                    <FaSortDown className={`sort-icon ${sortingParam === 'price-desc' ? 'sort-icon-active' : ''}`}/>
                                </div>
                            </div>
                            <div className="assets-filter">
                                <label className="assets-filter-label">{t('h_change')}</label>
                                <div onClick={() => {handleSetSortingParam("change")}} className="up-down-filter-icons-cont">
                                    <FaSortUp className={`sort-icon sort-topicon ${sortingParam === 'change' ? 'sort-icon-active' : ''}`}/>
                                    <FaSortDown className={`sort-icon ${sortingParam === 'change-desc' ? 'sort-icon-active' : ''}`}/>
                                </div>
                            </div>
                            <div className="assets-filter">
                                {t('actions')}
                            </div>
                        </div>
                        {assetElems}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wallet