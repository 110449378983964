import { create } from "zustand"
import axiosInstance from "../api/axiosInstance"
import { jwtDecode } from "jwt-decode"
import i18n from "../i18n"
import toast from "react-hot-toast"
import usePageLayoutStore from "./PageLayoutStore"

export interface LoginCredentials {
    username: string,
    password: string,
    googlecode?: string
}
export interface AccountInfo {
    email: string,
    password: string,
    captchaResponse: string,
    lang: string,
    username: string,
    password1: string,
    password2: string,
}

interface TokenPayload {
    exp: number
}

interface RefreshResponse {
    access: string,
    refresh: string
}

interface ResetPasswordPayload {
    new_password1: string,
    new_password2: string,
    token: string,
    uid: string
}

interface ChangePasswordLoggedPayload {
    old_password: string,
    new_password1: string,
    new_password2: string,
}



interface AuthStore {
    isLogged: boolean,
    isLoading: boolean,
    logIn: (data: LoginCredentials, handleGo2Fa: () => void) => Promise<string>,
    logOut: () => void,
    getAccessToken: () => Promise<string | null>,
    registerAccount: (data: AccountInfo) => Promise<boolean>,
    resendConfirmationCode: (email: string) => Promise<void>,
    verifyConfirmationCode: (code: string) => Promise<boolean>,
    sendResetPasswordRequest: (email: string, captchaToken: string) => Promise<boolean>,
    resetPassword: ({}: ResetPasswordPayload ) => Promise<string>,
    changePasswordLogged: ({}: ChangePasswordLoggedPayload ) => Promise<string>
}

const isTokenValid = (token: string): boolean => {
    try {
        const decodedToken: any = jwtDecode(token)
        const isExpired = decodedToken.exp * 1000 < Date.now()
        return !isExpired
    } catch (error) {
        return false
    }
}

const useAuthStore = create<AuthStore>()((set, get) => {

    const setSidebarPosition = usePageLayoutStore.getState().setSidebarPosition
    
    return {
        isLogged: isTokenValid(localStorage.getItem("refresh_token") || ""),
        isLoading: false,
        logIn: async (credentials, handleGo2Fa) => {
            set({ isLoading: true })
            try {
                toast.dismiss()
                const res = await axiosInstance.post('/auth/login/', credentials)
                localStorage.setItem("access_token", res.data.access_token)
                localStorage.setItem("refresh_token", res.data.refresh_token)
                set({ isLogged: true })
                set({ isLoading: false })
                return "success"
            }
            catch (error: any) {
                console.error("Login failed", error)
                set({ isLoading: false })
                if(error?.response?.data?.type?.code === "two_fa_failed") {
                    if(credentials?.googlecode) {
                        return "otp_fail"
                    }
                    try {
                        handleGo2Fa()
                        return "go2fa"
                    }
                    catch {
                        return "error"
                    }
                }
                const message = error?.response?.data?.type?.[0]?.message
                if(!message) {
                    return "error"
                }
                if(message === 'wrong_data') {
                    toast.error(i18n.t('invalid_login'))
                }
                if(message === 'reset_psw') {
                    toast.error(i18n.t('reset_psw'))
                }
                return message
            }
        },
        logOut: () => {
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            setSidebarPosition(false)
            set({isLogged: false})
        },
        getAccessToken: async () => {
            const { logOut } = get()
            const access_token = localStorage.getItem("access_token")
            const refresh_token = localStorage.getItem("refresh_token")
            
            if (access_token) {
                try {
                    const payload: TokenPayload = jwtDecode(access_token)
                    const expTime = payload.exp * 1000
                    const curTime = new Date().getTime()

                    if (expTime - curTime <= 3000) {
                        try {
                            const response = await axiosInstance.post<RefreshResponse>("/auth/token/refresh/", {
                                refresh: refresh_token,
                            })
                            const newAccessToken = response.data.access
                            const newRefreshToken = response.data.refresh
                            localStorage.setItem("access_token", newAccessToken)
                            localStorage.setItem("refresh_token", newRefreshToken)
                            return newAccessToken
                        } 
                        catch (error) {
                            logOut()
                            throw error
                        }
                    }
                    return access_token
                } 
                catch(error) {
                    logOut()
                    throw error
                }
            }
            return null
        },
        registerAccount: async (data: AccountInfo) => {
            set({ isLoading: true })
            if (data.password !== data.password1){
                throw new Error('Passwords do not match')
            }
            try {
                await axiosInstance.post('/auth/registration/', data)
                toast.dismiss()
                toast.success(i18n.t("register_success"))
                set({ isLoading: false })
                return true
            }
            catch (error) {
                console.error("Registration failed", error)
                toast.dismiss()
                toast.error(i18n.t("register_fail"))
                set({ isLoading: false })
                return false
            }
        },
        resendConfirmationCode: async (email) => {
            toast.dismiss()
            set({ isLoading: true })
            try {
                await axiosInstance.post("auth/registration/resend-email/", {
                    email: email
                })
                toast.success(i18n.t('resent_code'))
            }
            catch(error) {
                console.log(error)
            }
            finally {
                set({ isLoading: false })
            }
        },
        verifyConfirmationCode: async (code) => {
            toast.dismiss()
            set({ isLoading: true })
            try {
                await axiosInstance.post("auth/registration/verify-email/", {
                    key: code
                })
                toast.success(i18n.t('email_confirmed'))
                set({ isLoading: false })
                return true
            }
            catch(error) {
                console.log(error)
                toast.error(i18n.t('incorrect_code'))
                set({ isLoading: false })
                return false
            }
        },
        sendResetPasswordRequest: async (email: string, captchaToken: string) => {
            set({ isLoading: true })
            toast.dismiss()
            try {
                await axiosInstance.post("auth/password/reset/", {
                    email: email,
                    lang: i18n.language,
                    captcha: captchaToken
                })
                toast.success(i18n.t('email_sent'))
                set({ isLoading: false })
                return true
            }
            catch(error) {
                console.log(error)
                toast.error(i18n.t('error'))
                set({ isLoading: false })
                return false
            }
        },
        resetPassword: async (password_payload) => {
            toast.dismiss()
            set({ isLoading: true })
            try {
                await axiosInstance.post("auth/password/reset/confirm/", password_payload)
                toast.success(i18n.t('password_reset_success'))
                set({ isLoading: false })
                return "success"
            }
            catch(error: any) {
                console.log(error)
                if(error.response.data.token[0].code === "invalid") {
                    toast.error(i18n.t('reset_code_expired'))
                    set({ isLoading: false })
                    return "invalid"
                }
                toast.error(i18n.t('error'))
                set({ isLoading: false })
                return "error"
            }
        },
        changePasswordLogged: async (password_payload) => {
            toast.dismiss()
            set({ isLoading: true })
            try {
                await axiosInstance.post("auth/password/change/", password_payload)
                toast.success(i18n.t('password_reset_success'))
                set({ isLoading: false })
                return "success"
            }
            catch(error: any) {
                console.log(error)
                if(error.response.data?.old_password?.msg?.code === "invalid") {
                    toast.error(i18n.t('wrong_old_password'))
                    set({ isLoading: false })
                    return "invalid"
                }
                toast.error(i18n.t('error'))
                set({ isLoading: false })
                return "error"
            }
        }
    }
})

export default useAuthStore
