import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from "@sentry/react";
import local_config from "./local_config.tsx"
import { HelmetProvider } from "react-helmet-async";
import App from './App.tsx'
import './i18n.ts';
import './index.css'


Sentry.init({
  dsn: "https://e51ffa23f2e0fb393239d3bf7c8c7c8c@o4508367323856896.ingest.us.sentry.io/4508399987392512", 
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  environment: local_config.sentryEnv ? local_config.sentryEnv : "noenv",
  tracePropagationTargets: [
    "localhost", 
    /^https:\/\/yourserver\.io\/api/,
    /^https:\/\/exchange\.nemolatam\.co/,
    /^https:\/\/exchange\.nemolatam\.com/,
    /^https:\/\/exchange\.testperu\.com/
  ],
  
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})


const queryClient = new QueryClient()

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
)
