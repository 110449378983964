import { useBalance } from "../../hooks/useBalance"
import { useCoins } from "../../hooks/useCoins"
import { Link } from "react-router-dom"
import { IoMdSwap } from "react-icons/io";
import { FiArrowUpLeft, FiArrowDownRight } from "react-icons/fi";
import { useTranslation } from "react-i18next"
import coinlogos from "../../utils/coinlogos"
import { numberWithCommas } from "../../utils/functions";
import "./AssetModal.css"

const AssetModal = ({ coin }: {coin: string}) => {

    const { t } = useTranslation('common')

    const { data: balanceData } = useBalance()
    const { data: coinsData } = useCoins()

    return (
        <div className="asset-modal-main-cont">
            <div className="asset-modal-topcont">
                <img className="asset-modal-logo" src={coinlogos[coin as keyof typeof coinlogos]}/>
                <span className="asset-modal-longname">{t(`long_coin_names.${coin}`)}</span>
                <span className="asset-modal-shortname">{coin}</span>
            </div>
            <div className="asset-modal-midcont">
                <Link className="asset-modal-action-link" to={`/converter/${coin}/${coin === "USDT" ? "COP" : "USDT"}`}><IoMdSwap /></Link>
                <Link className="asset-modal-action-link" to={`/deposit/${coin}`}><FiArrowDownRight /></Link>
                <Link className="asset-modal-action-link" to={`/withdraw/${coin}`}><FiArrowUpLeft /></Link>
            </div>
            <div className="asset-modal-botcont">
                <div className="asset-modal-botcont-cont">
                    <span className="asset-modal-botcont-minitext">{t('amount')}</span>
                    <span className="asset-modal-botcont-maintext">{numberWithCommas(`${balanceData.balance[coin].actual}`, coinsData?.coins[coin]?.decimals)} {coin}</span>
                    {coin !== "USDT" && <span className="asset-item-usdt-total">≈ {numberWithCommas(`${coinsData.coins[coin].price * (balanceData.balance[coin].actual + balanceData.balance[coin].orders)}`, 2)} USDT</span>}
                </div>
                <div className="asset-modal-botcont-cont">
                    <span className="asset-modal-botcont-minitext">{t('coin_price')}</span>
                    <span className="asset-modal-botcont-maintext">{numberWithCommas(`${coinsData.coins[coin].price}`,5)} USDT</span>
                </div>
                <div className="asset-modal-botcont-cont">
                    <span className="asset-modal-botcont-minitext">{t('h_change')}</span>
                    <span className="asset-modal-botcont-maintext" style={{color: coinsData.coins[coin].price_24h === 0 ? "white" : coinsData.coins[coin].price_24h > 0 ? "#46CE6C" : "#EE4747"}}>{coinsData.coins[coin].price_24h.toFixed(2)}%</span>
                </div>
            </div>
        </div>
    )

}

export default AssetModal