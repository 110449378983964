import axiosInstance from "../api/axiosInstance"
import { useQuery, useMutation } from "@tanstack/react-query"
import localConfig from "../local_config"

const fetchInswitch = (direction: string, currency: string) => {
    return axiosInstance.get("/in-payment-methods", {
        params: {
            direction: direction,
            currency: currency,
            country: localConfig.countryCode
        }
    })
}

const postInswitch = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/submit_payment/", formData);
};

export const useInswitch = (direction: string, currency: string) => {
    return useQuery({
        queryKey: ['inswitch', direction],
        queryFn: () => fetchInswitch(direction, currency),
        select: (data) => {
            return data.data
        }
    })
}

export const usePostInswitch = () => {
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postInswitch(formData),
    });
}

