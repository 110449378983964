const localConfig = {
    domain: "exchange.testperu.com",
    api: "https://exchange.testperu.com/api/v1/",
    base: "",
    title: "NemoLATAM",
    captcha: "6LcO-HAqAAAAADA7BzWwQh0jLPULBDXFuMLXm-_C",
    countryCode: "CO",
    countryCurrency: "COP",
    sentryEnv: "staging",
  }
  export default localConfig;
  
